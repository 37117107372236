<script lang="ts">
    export let animationDelays = [0, 150, 300];
</script>

<div class="flex w-full flex-row-reverse pr-1">
    <div
        class="message-bubble m-0 rounded-[18px] rounded-tr-none bg-gradient-to-r from-bmn-400/50 to-bmn-500/50 px-[12px] py-[9px] text-white"
    >
        <div class="flex items-center space-x-1">
            {#each animationDelays as delay}
                <div class="h-2 w-2 animate-bounce rounded-full bg-white" style="animation-delay: {delay}ms"></div>
            {/each}
        </div>
    </div>
</div>
